<template>
  <div>
    <AppBar :title="$t('customers.labels.title')"/>
    <v-card v-if="customer" flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-chip v-if="customer.isVerified">{{ $t('customers.labels.verified') }}</v-chip>
        <v-btn v-else depressed color="success" @click="markAsVerified" :disabled="loading" class="ml-4">{{ $t('customers.labels.markAsVerified') }}</v-btn>
      </v-card-title>
      <v-card-text v-if="customer">
        <v-form ref="addUser" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="customer.name" :label="$t('customers.headers.name')" :rules="requiredText"></v-text-field>
              <v-text-field v-model="customer.surname" :label="$t('customers.headers.surname')" :rules="requiredText"></v-text-field>
              <v-text-field :value="customer.email" :label="$t('customers.headers.email')" disabled></v-text-field>
              <v-text-field v-model="customer.street" :label="$t('customers.labels.street')" :rules="streetRules"></v-text-field>
              <v-text-field v-model="customer.zipCode" :label="$t('customers.labels.zipCode')" :rules="zipCode"></v-text-field>
              <v-text-field v-model="customer.city" :label="$t('customers.headers.city')" :rules="requiredText"></v-text-field>
              <!-- <v-text-field v-model="customer.phone" label="Telefon" :rules="phoneRules"></v-text-field> -->
              <div class="mb-5">
                <MazPhoneNumberInput v-model="phoneNumber" size="sm" show-code-on-list no-flags @update="phoneResult = $event" />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="2">
            <v-btn v-if="id" depressed color="error" @click="remove" :disabled="loading" rounded>{{ $t('customers.labels.remove') }}</v-btn>
          </v-col>
          <v-col cols="12" offset-md="2" md="1" class="text-right">
            <v-btn depressed color="secondary" @click="save" :disabled="loading || !valid || !phoneResult.isValid" :loading="loading" rounded>{{ $t('customers.labels.save') }}</v-btn>
          </v-col>
          <v-col cols="12" md="1" class="text-right">
            <v-btn depressed @click="close" :disabled="loading" rounded>{{ $t('customers.labels.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <Confirm ref="confirm"></Confirm>
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
import { displayInfo } from '@/utils/helpers'
import Confirm from '../../components/dialogs/Confirm.vue'

export default {
  components: { Confirm },
  name: 'PrivateCustomer',
  data () {
    return {
      loading: false,
      valid: true,
      id: this.$route.params.id,
      phoneNumber: null,
      phoneResult: {
        isValid: false
      },
      customer: null,
      // required: [(v) => !!v || this.$t('validation.fieldRequired')],
      requiredText: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length < 50 || this.$t('customers.validation.tooManyCharacters')
      ],
      zipCode: [
        v => !!v || this.$t('customers.validation.zipCodeRequired'),
        v => /^[0-9]{2}-[0-9]{3}$/.test(v) || this.$t('customers.validation.zipCodeFormat')
      ],
      vatNumber: [
        v => !!v || this.$t('customers.validation.vatNumberRequired'),
        v => /^[0-9]{10}$/.test(v) || this.$t('customers.validation.vatNumberFormat')
      ],
      streetRules: [
        v => !!v || this.$t('customers.validation.streetRequired'),
        v => (v || '').length < 100 || this.$t('customers.validation.tooManyCharacters')
      ],
      phoneRules: [
        v => !!v || this.$t('customers.validation.phoneRequired'),
        v => /^(([0-9])+\d{8})$/.test(v) || this.$t('customers.validation.phoneFormat'),
        v => (v || '').length < 20 || this.$t('customers.validation.phoneLength')
      ]
    }
  },
  async created () {
    await this.getCustomer()
  },
  methods: {
    async getCustomer () {
      this.loading = true

      try {
        const { data } = await Service.getById('PrivateCustomers', this.$route.params.id)
        this.customer = data
        this.phoneNumber = '+' + this.customer.phone.countryCallingCode + this.customer.phone.number
      } catch (error) {}

      this.loading = false
    },
    async save () {
      this.loading = true
      this.$refs.addUser.validate()

      if (!this.valid) return

      if (!this.phoneResult.isValid) return

      this.customer.phone = {
        countryCode: this.phoneResult.countryCode,
        countryCallingCode: this.phoneResult.countryCallingCode,
        number: this.phoneResult.nationalNumber
      }

      try {
        await Service.put(`/PrivateCustomers/${this.$route.params.id}`, this.customer)

        displayInfo(this.$t('customers.notifications.successfullySavedChanges'))
        // this.$router.push({ path: '/private-customers' })
      } catch (error) {}

      this.loading = false
    },
    async remove () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('customers.labels.removeCustomerAccount'), `${this.$t('customers.confirmations.deleteCustomerAccount')} ${this.customer.name} ${this.customer.surname}?`)) {
        try {
          await Service.delete('Customers', this.customer.id)

          this.$refs.addUser.reset()
          displayInfo(this.$t('customers.notifications.successfullyDeletedCustomerAccount'))
          this.$router.push({ path: '/private-customers' })
        } catch (error) {}
      }

      this.loading = false
    },
    async markAsVerified () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('customers.labels.markAsVerified'), `${this.$t('customers.confirmations.markAsVerified')} ${this.customer.name} ${this.customer.surname} ${this.$t('customers.confirmations.asVerified')}`)) {
        try {
          await Service.post(`PrivateCustomers/${this.customer.id}/Verify`)

          displayInfo(this.$t('customers.notifications.successfullyMarkedAsVerified'))
          await this.getCustomer()
        } catch (error) {}
      }

      this.loading = false
    },
    close () {
      this.$router.push({ path: '/private-customers' })
    }
  }
}
</script>
