<template>
  <div>
    <AppBar :title="$t('customers.labels.title')"/>
    <v-card v-if="customer" flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <!-- <v-chip v-if="customer.isVerified" class="ml-4">Klient zweryfikowany</v-chip>
        <v-btn v-else depressed color="success" @click="markAsVerified" :disabled="loading" class="ml-4"> Oznacz jako zweryfikowany </v-btn> -->
      </v-card-title>
      <v-card-text v-if="customer">
        <v-form ref="addUser" v-model="valid">
        <v-row>
          <v-col cols="12" md="6">
            <v-select v-model="customer.title.id" :label="$t('customers.headers.courtesyTitle')" :items="titleEnum" :rules="required"></v-select>
            <v-text-field v-model="customer.name" :label="$t('customers.headers.name')" :rules="requiredText"></v-text-field>
            <v-text-field v-model="customer.surname" :label="$t('customers.headers.surname')" :rules="requiredText"></v-text-field>
            <v-text-field :value="customer.email" :label="$t('customers.headers.email')" disabled></v-text-field>
            <v-select v-model="customer.nationality" :label="$t('customers.headers.nationality')" :items="countries" item-text="name" item-value="code" :rules="required"></v-select>
            <v-select v-model="customer.countryOfResidence" :label="$t('customers.headers.countryOfResidence')" :items="countries" item-text="name" item-value="code" :rules="required"></v-select>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="customer.dateOfBirth" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="customer.dateOfBirth" :label="$t('customers.headers.dateOfBirth')" readonly v-bind="attrs" v-on="on">
                  <template v-slot:append>
                    <v-icon size="20" color="secondary" class="pt-1">mdi-calendar-month</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="customer.dateOfBirth" scrollable >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false" rounded>
                  {{ $t('buttons.close') }}
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(customer.dateOfBirth)" rounded>
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <div class="mb-5">
              <MazPhoneNumberInput v-model="phoneNumber" size="sm" show-code-on-list no-flags @update="phoneResult = $event" :rules="required" />
            </div>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
        <v-col cols="12" md="2">
          <v-btn v-if="id" depressed color="error" @click="remove" :disabled="loading" class="ml-auto" rounded> {{ $t('customers.labels.remove') }} </v-btn>
        </v-col>
        <v-col cols="12" offset-md="2" md="1">
          <v-btn depressed color="secondary" @click="save" :disabled="loading" :loading="loading" class="ml-4" block rounded> {{ $t('buttons.save') }} </v-btn>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn depressed class="ml-4" @click="close" :disabled="loading" block rounded> {{ $t('buttons.close') }} </v-btn>
        </v-col>
        </v-row>
      </v-card-actions>
      <Confirm ref="confirm"></Confirm>
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
import { displayInfo } from '@/utils/helpers'
import Confirm from '@/components/dialogs/Confirm.vue'
import countryCodes from '@/utils/countryCodes.json'

export default {
  components: { Confirm },
  name: 'CustomerUAE',
  data () {
    return {
      loading: false,
      valid: true,
      id: this.$route.params.id,
      customer: null,
      phoneNumber: null,
      phoneResult: null,
      countries: countryCodes,
      modal: false,
      titleEnum: [
        { text: 'Mr', value: 1 },
        { text: 'Mrs', value: 2 },
        { text: 'Ms', value: 3 }
      ],
      requiredText: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length < 50 || this.$t('customers.validation.tooManyCharacters')
      ],
      zipCode: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /^[0-9]{2}-[0-9]{3}$/.test(v) || this.$t('customers.validation.zipCodeFormat')
      ],
      vatNumber: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /^[0-9]{10}$/.test(v) || this.$t('customers.validation.vatNumberFormat')
      ],
      streetRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length < 100 || this.$t('customers.validation.tooManyCharacters')
      ],
      required: [
        v => !!v || this.$t('validation.fieldRequired')
      ]
    }
  },
  async created () {
    await this.getCustomer()
  },
  methods: {
    async getCustomer () {
      this.loading = true

      try {
        const { data } = await Service.getById('DubaiCustomers', this.$route.params.id)
        this.customer = data
        this.phoneNumber = '+' + this.customer.phone.countryCallingCode + this.customer.phone.number
      } catch (error) {}

      this.loading = false
    },
    async save () {
      this.loading = true
      this.$refs.addUser.validate()

      if (!this.valid) return

      try {
        await Service.put(`/DubaiCustomers/${this.$route.params.id}`, {
          title: this.customer.title.id,
          name: this.customer.name,
          surname: this.customer.surname,
          phone: {
            countryCode: this.phoneResult.countryCode,
            number: this.phoneResult.nationalNumber
          },
          nationality: this.customer.nationality,
          countryOfResidence: this.customer.countryOfResidence,
          dateOfBirth: this.customer.dateOfBirth
        })

        displayInfo(this.$t('customers.notifications.successfullySavedChanges'))
        this.$router.push({ path: '/customers-uae' })
      } catch (error) {}

      this.loading = false
    },
    async remove () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('customers.labels.removeCustomerAccount'), `${this.$t('customers.confirmations.deleteCustomerAccount')} ${this.customer.name} ${this.customer.surname}?`)) {
        try {
          await Service.delete('Customers', this.customer.id)

          this.$refs.addUser.reset()
          displayInfo(this.$t('customers.notifications.successfullyDeletedCustomerAccount'))
          this.close()
        } catch (error) {}
      }

      this.loading = false
    },
    close () {
      this.$router.push({ path: '/customers-uae' })
    }
  }
}
</script>
